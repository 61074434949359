import { createSlice } from "@reduxjs/toolkit";
import { StyleMode } from "../../common/enumerations";
import { LocalStorageKey, readLocalStorage } from "../../local-storage";
import { CustomColor } from "../../styling";
import { SetStyleModeAction } from "./general.action";

const getStoredStyleMode = () => {
  const storedStyleMode = readLocalStorage(LocalStorageKey.styleMode);
  return storedStyleMode ? (storedStyleMode as StyleMode) : StyleMode.DARK;
};

const generalSlice = createSlice({
  name: "general",
  initialState: {
    currentStyleMode: getStoredStyleMode(),
    currentBackgroundColor: CustomColor.MuiBackgroundDark,
  },
  reducers: {
    setCurrentStyleMode: (state, action) => {
      const styleMode = (action as SetStyleModeAction).payload;
      state.currentStyleMode = styleMode;
      state.currentBackgroundColor =
        styleMode === StyleMode.LIGHT
          ? CustomColor.MuiBackgroundLight
          : CustomColor.MuiBackgroundDark;
    },
  },
});

export const GeneralReducer = generalSlice.reducer;

export const { setCurrentStyleMode } = generalSlice.actions;

import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Language } from "../common/enumerations";
import { getLanguage } from "../helper";
import i18n from "../localizer";
import { RootPage } from "../pages";

export const PrivateRoutes = () => {
  const currentLocation = useLocation();
  const lng: Language = getLanguage(currentLocation.pathname);
  i18n.changeLanguage(lng);

  return (
    <Routes>
      <Route path={`/${lng}`} element={<RootPage />}></Route>
      <Route
        path={"/"}
        element={<Navigate to={`${lng}/`} replace={true} />}
      ></Route>
      <Route
        path={"*"}
        element={<Navigate to={`${lng}/`} replace={true} />}
      ></Route>
    </Routes>
  );
};

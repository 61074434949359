import { ToggleButtonGroup, ToggleButton, Tooltip } from "@mui/material";
import LightIcon from "@mui/icons-material/LightModeOutlined";
import DarkIcon from "@mui/icons-material/DarkModeOutlined";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { LngNs, StyleMode } from "../../common/enumerations";
import { useTranslation } from "react-i18next";
import {
  LocalStorageKey,
  readLocalStorage,
  writeLocalStorage,
} from "../../local-storage";
import { CurrentStyleModeSelector } from "../../store/general/general.selector";
import { setCurrentStyleMode } from "../../store/general/general.reducer";

export const ThemeSelector = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const storedStyleMode = readLocalStorage(LocalStorageKey.styleMode);
  const defaultStyleMode = CurrentStyleModeSelector();
  const [mode, setMode] = useState<string>(
    (defaultStyleMode as StyleMode) || StyleMode.DARK
  );

  useEffect(() => {
    dispatch(setCurrentStyleMode(storedStyleMode));
  });

  const handleModeChange = (
    event: React.MouseEvent<HTMLElement>,
    newMode: string
  ) => {
    setMode(newMode);
    writeLocalStorage(LocalStorageKey.styleMode, newMode);
  };

  return (
    <ToggleButtonGroup
      exclusive
      aria-label={t(`${translations}styleMode.radioGroup`)}
    >
      <Tooltip title={t(`${translations}styleMode.dark`)}>
        <ToggleButton
          value={StyleMode.DARK}
          aria-label={t(`${translations}styleMode.dark`)}
          selected={mode === StyleMode.DARK}
          onChange={handleModeChange}
        >
          <DarkIcon />
        </ToggleButton>
      </Tooltip>
      <Tooltip title={t(`${translations}styleMode.light`)}>
        <ToggleButton
          value={StyleMode.LIGHT}
          aria-label={t(`${translations}styleMode.light`)}
          selected={mode === StyleMode.LIGHT}
          onChange={handleModeChange}
        >
          <LightIcon />
        </ToggleButton>
      </Tooltip>
    </ToggleButtonGroup>
  );
};

const translations = `${LngNs.common}:`;

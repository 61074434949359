import { FormControlLabel, Radio, RadioGroup, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Language, LngNs } from "../../common/enumerations";
import { getLanguage } from "../../helper";

export const LanguageSelector = () => {
  const { t } = useTranslation();
  const currentLocation = useLocation();
  const navigate = useNavigate();
  const lng: Language = getLanguage(currentLocation.pathname);

  const handleChange = (newLng: Language) => {
    if (lng !== newLng) {
      navigate(`/${newLng}`, { replace: false });
    }
  };

  return (
    <RadioGroup
      row
      aria-label={t(`${translations}language.radioGroup`)}
      name="languageGroup"
    >
      <Tooltip title={t(`${translations}language.en`)}>
        <FormControlLabel
          value={Language.EN}
          control={<Radio size="small" />}
          label="EN"
          checked={lng === Language.EN}
          onChange={() => handleChange(Language.EN)}
        />
      </Tooltip>
      <Tooltip title={t(`${translations}language.de`)}>
        <FormControlLabel
          value={Language.DE}
          control={<Radio size="small" />}
          label="DE"
          checked={lng === Language.DE}
          onChange={() => handleChange(Language.DE)}
        />
      </Tooltip>
    </RadioGroup>
  );
};

const translations = `${LngNs.common}:`;

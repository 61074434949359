import { styled } from "@mui/material";

const childMinHeight = 0;
const childMargin = 0;
const childPadding = 0.25;
const contentMargin = 2.25;

interface OuterFrameProps {
  custombackgroundcolor: string;
}

export const OuterFramePropped = styled("div", {
  shouldForwardProp: (prop) => prop !== "success",
})<OuterFrameProps>(({ custombackgroundcolor, theme }) => ({
  margin: theme.spacing(4),

  [theme.breakpoints.down("sm")]: {
    margin: theme.spacing(4),
  },
  // eslint-disable-next-line
  [".MuiAccordionSummary-root"]: {
    minHeight: theme.spacing(childMinHeight),
    margin: theme.spacing(childMargin),
    padding: theme.spacing(childPadding),
    flexDirection: "row-reverse",
  },
  // eslint-disable-next-line
  [".MuiAccordionSummary-content"]: {
    margin: theme.spacing(childMargin),
    padding: theme.spacing(childPadding),
  },
  // eslint-disable-next-line
  ["& .MuiAccordionSummary-expandIconWrapper.Mui-expanded"]: {
    transform: "rotate(90deg)",
  },
  // eslint-disable-next-line
  [".MuiAccordionDetails-root"]: {
    margin: theme.spacing(childMargin),
    padding: theme.spacing(childPadding),
    marginLeft: theme.spacing(contentMargin),
  },
  // eslint-disable-next-line
  [".css-h4kk52-MuiPaper-root-MuiAccordion-root:before"]: {
    backgroundColor: custombackgroundcolor,
  },
  // eslint-disable-next-line
  [".MuiBox-root"]: {
    margin: theme.spacing(childMargin),
    padding: theme.spacing(childPadding),
  },
  // eslint-disable-next-line
  [".MuiPaper-root"]: {
    marginLeft: theme.spacing(0.5),
  },
  // eslint-disable-next-line
  [".MuiAccordion-rounded"]: {
    // BORDER CHEAT:
    border: "solid",
    borderColor: custombackgroundcolor,
    borderWidth: 1,
    outlineStyle: "solid",
    outlineColor: custombackgroundcolor,
    outlineWidth: 1,
  },
}));

// Supported languages
export enum Language {
  DE = "de",
  EN = "en",
}

// Language namespaces (files)
export enum LngNs {
  common = "common",
}

export enum StyleMode {
  DARK = "dark",
  LIGHT = "light",
}

import { useSelector } from "react-redux";
import { StyleMode } from "../../common/enumerations";
import { RootStoreType } from "../store";

export const CurrentStyleModeSelector = () =>
  useSelector(
    (state: RootStoreType) => state.general.currentStyleMode || StyleMode.DARK
  );

export const CurrentBackgroundColorSelector = () =>
  useSelector((state: RootStoreType) => state.general.currentBackgroundColor);

import { createTheme } from "@mui/material/styles";
import { StyleMode } from "../common/enumerations";

export enum CustomColor {
  MuiBackgroundDark = "#121212",
  MuiBackgroundLight = "#FFFFFF",
  Black = "#000000",
  SoftBlack = "#102030",
  BrightBlack = "#133353",
  Gray = "#A0A0A0",
  DarkGray = "#707070",
  LightGray = "#E0E0E0",
  Blue = "#5048E5",
  DarkBlue = "#082958",
  NightBlue = "#142540",
  Green = "#14B8A6",
  Red = "#D14343",
  White = "#FFFFFF",
  WhiteDirty = "#EEEEEE",
  Yellow = "#FFB020",
}

export const Spacing = {
  between: { x: 4, y: 2 },
  typography: {
    h1: { mt: 0, mb: 4 },
    h2: { mt: 5, mb: 3 },
    h3: { mt: 4, mb: 2 },
    h4: { mt: 3, mb: 1 },
  },
};

export const themeSpacingPx = 8;

export const getPixels = (spacing: number) => {
  return spacing * themeSpacingPx;
};

export const getDesignTokens = (mode: StyleMode) => ({
  ...BaseTheme,
  palette: {
    mode,
    ...(mode === StyleMode.LIGHT
      ? {
          // background: {
          //   default: CustomColor.MuiBackgroundLight,
          // },
          text: {
            primary: CustomColor.SoftBlack,
          },
          primary: {
            main: CustomColor.Blue,
          },
        }
      : {
          // background: {
          //   default: CustomColor.MuiBackgroundDark,
          // },
          text: {
            primary: CustomColor.WhiteDirty,
          },
          primary: {
            main: CustomColor.Blue,
          },
        }),
  },
});

const BaseTheme = createTheme({
  spacing: (factor: number) => `${factor * themeSpacingPx}px`,
  components: {
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          "&.MuiTypography-h1": {
            fontSize: "2.25rem",
            fontWeight: 400,
            lineHeight: 1.5,
            padding: 0,
            marginTop: getPixels(Spacing.typography.h1.mt),
            marginBottom: getPixels(Spacing.typography.h1.mb),
          },
          "&.MuiTypography-h2": {
            fontSize: "1.75rem",
            fontWeight: 400,
            lineHeight: 1.5,
            padding: 0,
            marginTop: getPixels(Spacing.typography.h2.mt),
            marginBottom: getPixels(Spacing.typography.h2.mb),
          },
          "&.MuiTypography-h3": {
            fontSize: "1.5rem",
            fontWeight: 400,
            lineHeight: 1,
            padding: 0,
            marginTop: getPixels(Spacing.typography.h3.mt),
            marginBottom: getPixels(Spacing.typography.h3.mb),
          },
          "&.MuiTypography-h4": {
            fontSize: "1.25rem",
            fontWeight: 200,
            lineHeight: 1,
            padding: 0,
            marginTop: getPixels(Spacing.typography.h4.mt),
            marginBottom: getPixels(Spacing.typography.h4.mb),
          },
          "&.MuiTypography-body1": {
            fontSize: "1rem",
            fontWeight: 400,
            lineHeight: 1.5,
            padding: 0,
          },
          "&.MuiTypography-body2": {
            fontSize: "1rem",
            fontWeight: 400,
            lineHeight: 1.5,
            padding: 0,
          },
          "&.MuiTypography-caption": {
            fontSize: "0.75rem",
            fontWeight: 400,
            lineHeight: 1,
            padding: 0,
          },
        },
      },
    },
  },
});

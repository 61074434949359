import { Box, Link, styled, Typography } from "@mui/material";
import React from "react";
import { Bookmark } from "../../models";

interface OwnProps {
  bookmark: Bookmark;
}

const Container = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

export const BookmarkComponent = (props: OwnProps) => {
  const { bookmark } = props;

  return (
    <Container>
      <Link href={bookmark.url} target="_blank" rel="noopener noreferrer">
        <Typography sx={{ marginLeft: 1 }}>{bookmark.name}</Typography>
      </Link>
    </Container>
  );
};

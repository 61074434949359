export enum LocalStorageKey {
  styleMode = "styleMode",
}

export function writeLocalStorage(key: LocalStorageKey, value: string) {
  localStorage.setItem(key, value);
}

export function readLocalStorage(key: LocalStorageKey) {
  return localStorage.getItem(key);
}

import { Box, Typography } from "@mui/material";
import React from "react";
import { BookmarkGroup } from "../../models";

interface OwnProps {
  group: BookmarkGroup;
}

export const BookmarkGroupComponent = (props: OwnProps) => {
  const { group } = props;

  return (
    <Box>
      <Typography>{group.name}</Typography>
    </Box>
  );
};

import data from "../../data/bookmark.data.json";
import { BookmarkCategory, BookmarkGroup } from "../../models";

export const getBookmarkCategories = (): BookmarkCategory[] =>
  data as BookmarkCategory[];

function filterBookmarks(
  filterTerm: string,
  bookmarkGroup: BookmarkGroup
): BookmarkGroup {
  const filteredBookmarks = bookmarkGroup.bookmarks.filter((b) => {
    if (b.name.toLowerCase().includes(filterTerm.toLowerCase())) return b;
  });

  const filteredGroups = bookmarkGroup.bookmarkGroups.map((g) =>
    filterBookmarks(filterTerm, g)
  );

  return {
    ...bookmarkGroup,
    bookmarks: filteredBookmarks,
    bookmarkGroups: filteredGroups,
  };
}

const containsBookmarks = (bookmarkGroup: BookmarkGroup): boolean => {
  return (
    bookmarkGroup.bookmarks.length > 0 ||
    bookmarkGroup.bookmarkGroups.some((g) => containsBookmarks(g))
  );
};

function removeEmptyGroups(currentGroup: BookmarkGroup) {
  let keepCurrentGroup = containsBookmarks(currentGroup);
  let childGroups: BookmarkGroup[] = [];

  currentGroup.bookmarkGroups.forEach((g) => {
    const result = removeEmptyGroups(g);
    if (result.keepCurrentGroup) childGroups.push(result.cleanGroup);
  });

  return {
    keepCurrentGroup,
    cleanGroup: { ...currentGroup, bookmarkGroups: childGroups },
  };
}

export const filteredBookmarkData = (
  filterTerm: string
): BookmarkCategory[] => {
  const categories = getBookmarkCategories();
  let filteredBookmarkData: BookmarkCategory[] = [];
  categories.forEach((c) => {
    filteredBookmarkData.push(filterBookmarks(filterTerm, c));
  });

  let cleanBookmarkData: BookmarkCategory[] = [];
  filteredBookmarkData.forEach((fc) => {
    cleanBookmarkData.push(removeEmptyGroups(fc).cleanGroup);
  });
  return cleanBookmarkData;
};

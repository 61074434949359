import { styled } from "@mui/material";

//#region BASE
const Flex = styled("div")(({ theme }) => ({
  display: "flex",
}));
//#endregion

// NOWRAP
//#region DIRECTION (NOWRAP)
export const FlexRow = styled(Flex)(({ theme }) => ({
  flexDirection: "row",
}));

export const FlexColumn = styled(Flex)(({ theme }) => ({
  flexDirection: "column",
}));
//#endregion

//#region CENTER (NOWRAP)
export const FlexRowCenter = styled(FlexRow)(({ theme }) => ({
  justifyContent: "center",
}));

export const FlexColumnCenter = styled(FlexColumn)(({ theme }) => ({
  justifyContent: "center",
}));
//#endregion

//#region CENTER ITEMS (NOWRAP)
export const FlexRowCenterItems = styled(FlexRow)(({ theme }) => ({
  alignItems: "center",
}));

export const FlexColumnCenterItems = styled(FlexColumn)(({ theme }) => ({
  alignItems: "center",
}));
//#endregion

// WRAP
//#region DIRECTION (WRAP)
export const FlexRowWrap = styled(FlexRow)(({ theme }) => ({
  flexWrap: "wrap",
}));

export const FlexColumnWrap = styled(FlexColumn)(({ theme }) => ({
  flexWrap: "wrap",
}));
//#endregion

//#region CENTER (WRAP)
export const FlexRowCenterWrap = styled(FlexRowCenter)(({ theme }) => ({
  flexWrap: "wrap",
}));

export const FlexColumnCenterWrap = styled(FlexColumnCenter)(({ theme }) => ({
  flexWrap: "wrap",
}));
//#endregion

//#region CENTER ITEMS (WRAP)
export const FlexRowCenterItemsWrap = styled(FlexRowCenterItems)(
  ({ theme }) => ({
    flexWrap: "wrap",
  })
);

export const FlexColumnCenterItemsWrap = styled(FlexColumnCenterItems)(
  ({ theme }) => ({
    flexWrap: "wrap",
  })
);
//#endregion

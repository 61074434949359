import { Language } from "../common/enumerations";

export const getLanguage = (pathname: string): Language => {
  const pathParts = pathname.split("/");
  const lng = pathParts.length > 1 ? pathParts[1] : "";

  switch (lng) {
    case "en":
    case "de":
      return lng as Language;
  }

  return Language.EN;
};

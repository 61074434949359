import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import { PrivateRoutes } from "./routing/private.routes";
import { CurrentStyleModeSelector } from "./store/general/general.selector";
import { getDesignTokens } from "./styling";

function AppTheme() {
  const currentStyleMode = CurrentStyleModeSelector();

  const theme = React.useMemo(
    () => createTheme(getDesignTokens(currentStyleMode)),
    [currentStyleMode]
  );

  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline is needed to enable dark mode for default palette: https://mui.com/material-ui/customization/dark-mode/ */}
      <CssBaseline />
      <PrivateRoutes />
    </ThemeProvider>
  );
}

export default AppTheme;

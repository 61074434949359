import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  styled,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/KeyboardArrowRight";
import React, { useEffect, useRef, useState } from "react";
import { Bookmark, BookmarkCategory, BookmarkGroup } from "../../models";
import { filteredBookmarkData } from "../../store";
import {
  BookmarkComponent,
  BookmarkGroupComponent,
  FlexRow,
  FlexRowCenterItems,
  LanguageSelector,
  ThemeSelector,
} from "../../components";
import { CustomColor, Spacing } from "../../styling";
import SearchIcon from "@mui/icons-material/Search";
import CollapseAllIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import ExpandeAllIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { LngNs } from "../../common/enumerations";
import { OuterFramePropped } from "./outer-frame.component";
import { CurrentBackgroundColorSelector } from "../../store/general/general.selector";

const typographyMarginLeft = 2;

const ToolBar = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
}));

const ExpansionContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(1),
  marginInline: theme.spacing(2),
}));

const updateExpandedDict = (
  previousState: { [key: string]: boolean },
  bookmarkData: BookmarkGroup,
  isExpanded: boolean
): { [key: string]: boolean } => {
  let newState: { [key: string]: boolean } = { ...previousState };

  bookmarkData.bookmarkGroups.forEach((g) => {
    newState[`${g.id}`] = isExpanded;
    const childState = updateExpandedDict(newState, g, isExpanded);
    newState = Object.assign(newState, childState);
  });

  return { ...newState };
};

const expandAll = (
  previousState: { [key: string]: boolean },
  bookmarkData: BookmarkCategory[]
): { [key: string]: boolean } => {
  let allExpandedPairs: { [key: string]: boolean } = { ...previousState };

  bookmarkData.forEach((category) => {
    allExpandedPairs = Object.assign(
      { ...allExpandedPairs },
      updateExpandedDict(allExpandedPairs, category, true)
    );
  });

  return allExpandedPairs;
};

const collapseAll = (
  previousState: { [key: string]: boolean },
  bookmarkData: BookmarkCategory[]
): { [key: string]: boolean } => {
  let allExpandedPairs: { [key: string]: boolean } = { ...previousState };

  bookmarkData.forEach((category) => {
    allExpandedPairs = Object.assign(
      { ...allExpandedPairs },
      updateExpandedDict(allExpandedPairs, category, false)
    );
  });

  return allExpandedPairs;
};

const initExpanded = (
  previousState: { [key: string]: boolean },
  bookmarkData: BookmarkCategory[]
): { [key: string]: boolean } => {
  return collapseAll(previousState, bookmarkData);
};

export const RootPage = () => {
  const { t } = useTranslation();
  const currentBackgroundColor = CurrentBackgroundColorSelector();

  const [searchTerm, setSearchTerm] = useState("");
  const hasSearchTerm = searchTerm !== "";
  const bookmarkData: BookmarkCategory[] = filteredBookmarkData(searchTerm);
  const hasElements = bookmarkData.some(
    (data) => data.bookmarks.length > 0 || data.bookmarkGroups.length > 0
  );
  const [expansionDict, setExpansionDict] = useState<{
    [key: string]: boolean;
  }>(initExpanded({}, bookmarkData));

  useEffect(() => {
    console.log("Copyright by Peter Biermanski");
  }, []);

  useEffect(() => {
    if (hasSearchTerm) {
      setExpansionDict(expandAll(expansionDict, bookmarkData));
    }
    // eslint-disable-next-line
  }, [hasSearchTerm, searchTerm]);

  const getBookmarks = (bookmarks: Bookmark[]) => {
    return bookmarks.map((bookmark, id) => {
      return <BookmarkComponent key={id} bookmark={bookmark} />;
    });
  };

  const handleAccordionClick = (event: React.MouseEvent<HTMLElement>) => {
    const groupId = event.currentTarget.id;
    setExpansionDict({ ...expansionDict, [groupId]: !expansionDict[groupId] });
  };

  const createAccordionSummary = (group: BookmarkGroup) => {
    return (
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id={`${group.id}`}
        onClick={handleAccordionClick}
        sx={{
          // BORDER CHEAT:
          border: "solid",
          borderColor: currentBackgroundColor,
          borderWidth: 1,
          outlineStyle: "solid",
          outlineColor: currentBackgroundColor,
          outlineWidth: 1,
        }}
      >
        <BookmarkGroupComponent key={group.id} group={group} />
      </AccordionSummary>
    );
  };

  const createAccordionDetails = (group: BookmarkGroup) => {
    return (
      <AccordionDetails>
        {getBookmarks(group.bookmarks)}
        {getBookmarkGroups(group.bookmarkGroups)}
      </AccordionDetails>
    );
  };

  const getBookmarkGroups = (bookmarkGroups: BookmarkGroup[]) => {
    return bookmarkGroups.map((group, id) => {
      return (
        <Accordion
          key={`${group.id}`}
          disableGutters
          elevation={0}
          expanded={expansionDict[group.id]}
        >
          {createAccordionSummary(group)}
          {createAccordionDetails(group)}
        </Accordion>
      );
    });
  };

  const createCategory = (
    name: string,
    bookmarks: Bookmark[],
    bookmarkGroups: BookmarkGroup[]
  ) => {
    const hasElements = bookmarks.length > 0 || bookmarkGroups.length > 0;
    const showCategory = !(!hasElements && hasSearchTerm);

    if (showCategory) {
      return (
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={`${name}Category`}>
          <Typography variant="h3" sx={{ marginLeft: typographyMarginLeft }}>
            {name}
          </Typography>
          <Paper
            elevation={0}
            sx={{
              padding: 1,
              border: "solid",
              borderColor: CustomColor.Gray,
              borderWidth: 1,
              borderRadius: 1,
            }}
          >
            {getBookmarks(bookmarks)}
            {getBookmarkGroups(bookmarkGroups)}
          </Paper>
        </Grid>
      );
    }
  };

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  const searchInput = useRef<HTMLInputElement>(null);

  const clearSearch = () => {
    setSearchTerm("");
    searchInput.current?.focus();
  };

  const searchField = () => {
    return (
      <TextField
        id="searchInput"
        inputRef={searchInput}
        label={t(`${translations}search`)}
        value={searchTerm}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {hasSearchTerm && (
                <Tooltip title={t(`${translations}clear`)}>
                  <IconButton onClick={clearSearch}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        fullWidth
        sx={{ marginLeft: 1, maxWidth: 300 }}
        onChange={(e) => handleSearchChange(e.target.value)}
      />
    );
  };

  const expansionButtons = () => {
    return (
      <ExpansionContainer>
        <Tooltip title={t(`${translations}expandAll`)}>
          <IconButton
            aria-label="expandAllBtn"
            onClick={() => {
              setExpansionDict(expandAll(expansionDict, bookmarkData));
            }}
          >
            <ExpandeAllIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t(`${translations}collapseAll`)}>
          <IconButton
            aria-label="collapseAllBtn"
            onClick={() => {
              setExpansionDict(collapseAll(expansionDict, bookmarkData));
            }}
          >
            <CollapseAllIcon />
          </IconButton>
        </Tooltip>
      </ExpansionContainer>
    );
  };

  return (
    <OuterFramePropped custombackgroundcolor={currentBackgroundColor}>
      <FlexRow
        sx={{ justifyContent: "space-between", alignItems: "flex-start" }}
      >
        <Typography variant="h1">{`${t(`${translations}youRefs`)} - ${t(
          `${translations}webManager`
        )}`}</Typography>
        <FlexRowCenterItems
          sx={{
            marginBottom: Spacing.typography.h1.mb,
            marginLeft: Spacing.between.x,
            minHeight: 40,
            maxHeight: 40,
          }}
        >
          <LanguageSelector />
          <ThemeSelector />
        </FlexRowCenterItems>
      </FlexRow>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <ToolBar>
            {searchField()}
            {expansionButtons()}
          </ToolBar>
        </Grid>
      </Grid>

      <Grid container columnSpacing={Spacing.between.x}>
        {hasElements ? (
          bookmarkData.map((category) => {
            return createCategory(
              category.name,
              category.bookmarks,
              category.bookmarkGroups
            );
          })
        ) : (
          <Grid item>
            <Typography ml={typographyMarginLeft} mt={Spacing.between.y}>
              {t(`${translations}noMatch`)}
            </Typography>
          </Grid>
        )}
      </Grid>
    </OuterFramePropped>
  );
};

const translations = `${LngNs.common}:`;

import { configureStore } from "@reduxjs/toolkit";
import { GeneralReducer } from "./general/general.reducer";

const RootStore = configureStore({
  reducer: {
    general: GeneralReducer,
  },
});

export type RootStoreType = ReturnType<typeof RootStore.getState>;

export default RootStore;
